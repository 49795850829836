exports.onClientEntry = () => {
	// Polyfills
	require('mdn-polyfills/Element.prototype.closest');
	require('intersection-observer');
	require('element-scroll-polyfill');

	// Track outgoing links
	document.body.addEventListener('click', (event) => {
		if (!window.ga) {
			// GA not loaded
			return;
		}

		const a = event.target.closest('a');

		if (!a) {
			// Not a link
			return;
		}

		if (a.origin === window.location.origin) {
			// Not an external link
			return;
		}

		// Do we need to redirect this window in a callback
		const redirect =
			event.button === 0
			&& !event.altKey
			&& !event.ctrlKey
			&& !event.metaKey
			&& !event.shiftKey
			&& !event.defaultPrevented
			&& (!a.target || a.target.toLowerCase() === '_self');

		window.ga('send', 'event', {
			eventCategory: 'Outbound Link',
			eventAction: 'click',
			eventLabel: a.href,
			transport: redirect ? 'beacon' : '',
			hitCallback: () => {
				if (redirect) {
					document.location = a.href;
				}
			},
		});

		if (redirect) {
			event.preventDefault();
		}
	});
};
